import { useState, useEffect } from 'react';

const useScaler = () => {
  const [totalMargin, setTotalMargin] = useState(100);
  const delayedResize = () => setTimeout(() => resize(), 1000);
  const resize = () => {
    const screenRatio = window.innerWidth / window.innerHeight;
    const wideRatio = 16 / 9;
    const narrowRatio = 4 / 3;

    // if screen is too narrow, the game is not playable
    // if (screenRatio < narrowRatio) console.warn('too narrow');
    // calculate total margin (in vh) proportionally
    let totalMargin =
      ((wideRatio - screenRatio) / (wideRatio - narrowRatio)) * 25;
    // the div shouldn't be overscaled
    if (totalMargin < 0) totalMargin = 0;

    setTotalMargin(totalMargin);
  };

  useEffect(() => {
    window.addEventListener('resize', delayedResize);
    return () => window.removeEventListener('resize', delayedResize);
  }, []);
  // resize for the first render
  if(totalMargin === 100) resize();

  // figure out div dimensions
  let viewHeight = ((100 - totalMargin) / 100) * window.innerHeight;
  let viewWidth = (viewHeight * 16) / 9;
  let vMargin = (window.innerHeight - viewHeight) / 2;
  let hMargin =
    (window.innerWidth - viewWidth) / 2 > 0
      ? (window.innerWidth - viewWidth) / 2
      : 0;

  // round up all numbers
  viewHeight = Math.ceil(viewHeight);
  viewWidth = Math.ceil(viewWidth);
  vMargin = Math.ceil(vMargin);
  hMargin = Math.ceil(hMargin);

  const width = `${viewWidth * window.devicePixelRatio}px`;
  const height = `${viewHeight * window.devicePixelRatio}px`;
  const transform = `scale(${1 / window.devicePixelRatio})`;
  const transformOrigin = 'top left';

  return {
    viewWidth,
    viewHeight,
    vMargin,
    hMargin,
    width,
    height,
    transform,
    transformOrigin,
    resize
  };
};

export default useScaler;
