import React, { useState, useContext, useEffect } from 'react';
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  TextField,
  CircularProgress,
  IconButton,
  Snackbar,
  SnackbarContent
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  ExpandMore,
  PhoneAndroid,
  PhoneIphone,
  DesktopWindows,
  Grade,
  GradeOutlined,
  Close
} from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';

import socialService from '../services/social';
import { GeneralStore } from '../context/general';

const useStyles = makeStyles(theme => ({
  actionButton: {
    marginRight: '8px'
  },
  actionPanel: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px'
  },
  dialogContent: {
    marginTop: '16px'
  },
  heading: {
    fontWeight: 'bold'
  },
  requireAvatar: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white'
  },
  requireTitle: {
    backgroundColor: theme.palette.primary.main,
    '& h6': {
      color: 'white'
    }
  },
  feedbackDetail: {
    flexDirection: 'column'
  },
  starsBlock: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  gradeUnselect: {
    color: 'grey',
    fontSize: '40px'
  },
  gradeSelect: {
    color: theme.palette.primary.main,
    fontSize: '40px'
  },
  feedbackSnackbar: {
    backgroundColor: grey[900],
    color: grey[50]
  },
  feedbackProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const HelpDialog = ({ showDialog, setShowDialog }) => {
  const generalStore = useContext(GeneralStore);
  const [star, setStar] = useState(0);
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const {
    actionButton,
    actionPanel,
    dialogContent,
    heading,
    requireAvatar,
    requireTitle,
    gradeUnselect,
    gradeSelect,
    starsBlock,
    feedbackDetail,
    feedbackSnackbar,
    feedbackProgress
  } = useStyles();
  useEffect(() => {
    setStar(generalStore.state.review.star);
    setComment(generalStore.state.review.comment);
  }, [generalStore.state.review]);
  const submitReview = async () => {
    setLoading(true);
    await socialService.submitReview(star, comment);
    generalStore.dispatch({
      type: 'UPDATE_REVIEW',
      payload: { star, comment }
    });
    setOpenSnackbar(true);
    setLoading(false);
  };
  const renderStars = () => {
    let stars = [];
    for (let i = 1; i <= 5; i++) {
      stars = [
        ...stars,
        star >= i ? (
          <Grade key={i} className={gradeSelect} onClick={() => setStar(i)} />
        ) : (
          <GradeOutlined
            key={i}
            className={gradeUnselect}
            onClick={() => setStar(i)}
          />
        )
      ];
    }
    return stars;
  };
  const renderFeedback = () => {
    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
          <Typography className={heading}>ให้คะแนนความพึงพอใจ</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={feedbackDetail}>
          <div className={starsBlock}>{renderStars()}</div>
          <div>
            <TextField
              id="comment"
              label="ความคิดเห็น"
              multiline
              margin="normal"
              variant="outlined"
              rows="2"
              fullWidth
              value={comment}
              onChange={e => setComment(e.target.value)}
            />
          </div>
        </ExpansionPanelDetails>
        <div className={actionPanel}>
          <Button
            className={actionButton}
            variant="contained"
            color="secondary"
            onClick={() => submitReview()}
            disabled={star < 1 || comment === '' || loading}
          >
            <Typography color="inherit">ส่งข้อมูล</Typography>
            {loading ? (
              <CircularProgress size={20} className={feedbackProgress} />
            ) : null}
          </Button>
        </div>
      </ExpansionPanel>
    );
  };
  return (
    <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
      <DialogTitle className={requireTitle}>ความช่วยเหลือ</DialogTitle>
      <DialogContent className={dialogContent}>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Typography className={heading}>ความต้องการขั้นต่ำ</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar className={requireAvatar}>
                    <PhoneAndroid />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  <strong>Android</strong> - Samsung Galaxy S7 or 4GB RAM /
                  Android 6.0
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar className={requireAvatar}>
                    <PhoneIphone />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  <strong>iOS</strong> - iPhone 6S, iPhone 6S Plus, iPhone SE,
                  iPad (2017), iPad Air (2019), iPad mini (2019), iPad Pro / iOS
                  12
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar className={requireAvatar}>
                    <DesktopWindows />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  <strong>PC or Mac</strong> - Intel Core i3 CPU, 8GB RAM /
                  Google Chrome Version 73.0 or Firefox Version 66.0
                </ListItemText>
              </ListItem>
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        {socialService.isFacebookLogin() ? renderFeedback() : null}
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Typography className={heading}>แจ้งปัญหาการใช้งาน</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              คลิกที่ <strong>"แจ้งปัญหาการใช้งาน"</strong>{' '}
              เพื่อแจ้งปัญหาการใช้งานไปยังเจ้าหน้าที่ฝ่ายสนับสนุน
            </Typography>
          </ExpansionPanelDetails>
          <div className={actionPanel}>
            {/* <Button
              className={actionButton}
              variant="contained"
              color="primary"
              onClick={() => window.open('https://forms.gle/asorC1zwtcYUQL4C7')}
            >
              <Typography color="inherit">ฟอร์มสำรวจความคิดเห็น</Typography>
            </Button> */}
            <Button
              variant="contained"
              color="secondary"
              onClick={() => window.open('https://forms.gle/rVnF3yZFuNVG5ahG9')}
            >
              <Typography color="inherit">แจ้งปัญหาการใช้งาน</Typography>
            </Button>
          </div>
        </ExpansionPanel>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={() => setOpenSnackbar(false)}
        >
          <SnackbarContent
            className={feedbackSnackbar}
            message={<span id="message-id">บันทึกข้อมูลสำเร็จ</span>}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => setOpenSnackbar(false)}
              >
                <Close />
              </IconButton>
            ]}
            ContentProps={{
              'aria-describedby': 'message-id'
            }}
          />
        </Snackbar>
      </DialogContent>
    </Dialog>
  );
};

export default HelpDialog;
