import React from 'react';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles({
  heading: {
    fontWeight: 'bold'
  }
});

const Expandable = ({ summary, description }) => {
  const { heading } = useStyles();
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMore />}>
        <Typography className={heading}>{summary}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography dangerouslySetInnerHTML={{ __html: description }} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Expandable;
