import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { LoaderProvider } from './context/loader';
import { TokenProvider } from './context/token';
import { GeneralProvider } from './context/general';
import theme from './theme';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'typeface-roboto';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <LoaderProvider>
        <TokenProvider>
          <GeneralProvider>
            <CssBaseline />
            <App />
          </GeneralProvider>
        </TokenProvider>
      </LoaderProvider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
