import React from 'react';

export const TokenStore = React.createContext();

const initialState = {
  token: '',
  refreshToken: false
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_TOKEN':
      return { ...state, token: action.payload };
    case 'UPDATE_TOKEN':
      return { ...state, token: action.payload, refreshToken: true };
    case 'RESET':
      return { ...state, refreshToken: false };
    default:
      return state;
  }
}

export function TokenProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <TokenStore.Provider value={value}>{props.children}</TokenStore.Provider>
  );
}
