import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  image: {
    border: ({ bordered }) =>
      bordered && `${theme.palette.grey['500']} solid 1px`,
    maxWidth: '100%',
    width: ({ stretched }) => stretched && '100%'
  },
  imageCaption: {
    margin: `${theme.spacing(1)}px 0px`
  },
  paragraph: {
    margin: '1em 0px'
  }
}));

const TextViewer = ({ content }) => {
  const { imageCaption, paragraph } = useStyles();
  if (content === '') return null;
  return (
    <>
      {content.blocks.map((block, index) => {
        switch (block.type) {
          case 'paragraph':
            return (
              <Typography
                key={index}
                className={paragraph}
                dangerouslySetInnerHTML={{ __html: block.data.text }}
              />
            );
          case 'header': {
            return (
              <Typography key={index} variant={`h${block.data.level}`}>
                {block.data.text}
              </Typography>
            );
          }
          case 'image': {
            return (
              <Box
                key={index}
                display="flex"
                flexDirection="column"
                alignItems="center"
                my={6}
                bgcolor={block.data.withBackground ? 'text.secondary' : null}
              >
                <Image data={block.data} />
                <Typography className={imageCaption} variant="caption">
                  {block.data.caption}
                </Typography>
              </Box>
            );
          }
          case 'list': {
            return (
              <Box key={`item${index}`} pl={2}>
                {block.data.items.map((item, itemIndex) => {
                  return (
                    <Box key={itemIndex} display="flex">
                      <Box mr={1}>
                        <Typography align="center">
                          {block.data.style === 'ordered'
                            ? `${itemIndex + 1}.`
                            : '•'}
                        </Typography>
                      </Box>
                      <Typography dangerouslySetInnerHTML={{ __html: item }} />
                    </Box>
                  );
                })}
              </Box>
            );
          }
          default:
            return null;
        }
      })}
    </>
  );
};

const Image = ({ data }) => {
  const { image } = useStyles({
    stretched: data.stretched,
    bordered: data.withBorder
  });
  return <img className={image} src={data.file.url} alt="" />;
};

export default TextViewer;
