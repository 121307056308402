import React from 'react';
import { Typography } from '@material-ui/core';
import { responsiveFontSizes } from '@material-ui/core/styles';
import { makeStyles, ThemeProvider, useTheme } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    backgroundImage: `url('images/coming-soon.jpg')`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    width: '100vw'
  },
  description: {
    fontFamily: 'Kanit, Roboto',
    lineHeight: '3.5rem',
    padding: '10%',
    position: 'absolute'
  }
});

const ComingSoon = () => {
  const { description, root } = useStyles();
  const theme = useTheme();
  return (
    <div className={root}>
      <ThemeProvider theme={responsiveFontSizes(theme)}>
        <Typography
          className={description}
          variant="h2"
          component="h1"
          color="primary"
          align="center"
        >
          เตรียมตัวพบกับเราเร็วๆ นี้
        </Typography>
      </ThemeProvider>
    </div>
  );
};

export default ComingSoon;
