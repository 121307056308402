import { createMuiTheme } from '@material-ui/core';

const muiTheme = createMuiTheme({
  palette: {
    background: {
      default: '#000000'
    }
  },
  typography: {
    useNextVariants: true
  }
});

const theme = {
  ...muiTheme,
  layout: {
    width: 'auto',
    [muiTheme.breakpoints.up('lg')]: {
      width: muiTheme.breakpoints.values.lg,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  responsiveImage: {
    width: '100%'
  },
  embedResponsive: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden'
  }
};

export default theme;
