import React, { useEffect, useRef, useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Scene from '../components/Scene';
import LoaderContext from '../context/loader';
import { TokenStore } from '../context/token';
import { enter } from '../services/analytics';
import socialService from '../services/social';

import { auth } from '../firebase';

const Energy = ({ history }) => {
  const loaderContext = useContext(LoaderContext);
  const frame = useRef(null);
  const [ready, setReady] = useState(false);

  //refreshToken
  const { state, dispatch } = React.useContext(TokenStore);
  useEffect(() => {
    if (state.refreshToken) {
      if (frame && ready) {
        frame.current.contentWindow.postMessage(
          {
            type: 'updateToken',
            payload: {
              token: state.token
            }
          },
          '*'
        );
      }
      console.log('refreshToken');
      dispatch({
        type: 'RESET'
      });
    }
  }, [state.refreshToken]);

  const handleMessage = e => {
    switch (e.data.type) {
      case 'loadScene':
        loaderContext.setStatus({ loading: true, scene: 'world' });
        setTimeout(() => history.push(`/${e.data.payload}`), 1000);
        break;
      case 'ready':
        setReady(true);
        getInitialize();
        break;
      case 'loaded':
        loaderContext.setStatus({ loading: false, scene: 'energy' });
        break;
      default:
        break;
    }
  };

  const getInitialize = async () => {
    const token = await auth.currentUser.getIdToken();
    frame.current.contentWindow.postMessage(
      {
        type: 'initialize',
        payload: {
          uId: auth.currentUser.uid,
          token,
          displayName: socialService.getDisplayName(),
          profilePicture: socialService.getPhotoUrl(),
          guest: auth.currentUser.email ? false : true
        }
      },
      '*'
    );
  };

  useEffect(() => {
    enter('energy');
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  return <Scene name="energy" frame={frame} swapZoom={true} />;
};

export default withRouter(Energy);
