import React, { useState } from 'react';

const LoaderContext = React.createContext();
export const LoaderProvider = ({ children }) => {
  const [status, setStatus] = useState({ loading: true, scene: 'world' });
  return (
    <LoaderContext.Provider value={{ status, setStatus }}>
      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderContext;
