import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: '#EFFBFF',
    border: 'solid 2px #C2FDFD'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const ChangeNameDialog = ({
  open,
  onBackdropClick,
  nameState,
  onChangeName,
  onSubmitName,
  loading
}) => {
  const { paper, textField, progress } = useStyles();
  return (
    <Dialog open={open} onBackdropClick={onBackdropClick} classes={{ paper }}>
      <DialogTitle>เปลี่ยนชื่อ</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          className={textField}
          value={nameState}
          onChange={e => onChangeName(e.target.value)}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={onSubmitName}
          color="primary"
          disabled={loading}
        >
          Save
          {loading && <CircularProgress size={24} className={progress} />}
        </Button>
        <Button variant="contained" onClick={onBackdropClick}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeNameDialog;
