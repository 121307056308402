import React, { useState, useEffect, useContext } from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import { auth, database } from './firebase';
import World from './screens/World';
import Login from './screens/Login';
import Energy from './screens/Energy';
import Trees from './screens/Trees';
import Seas from './screens/Seas';
import Tour from './screens/Tour';
import Personality from './screens/Personality';
import Test from './screens/Test';
import ComingSoon from './screens/ComingSoon';

import Loader from './components/Loader';
import LogoutPopup from './components/LogoutPopup';
import { TokenStore } from './context/token';
import socialService from './services/social';
import { GeneralStore } from './context/general';

const App = ({ history }) => {
  const { dispatch } = useContext(TokenStore);
  const generalStore = useContext(GeneralStore);

  const [authChanged, setAuthChanged] = useState(false);
  const [open, setOpen] = useState(false);
  let token = '';
  let listener = null;
  useEffect(() => {
    ReactGA.initialize('UA-137035949-1');
    if (process.env.REACT_APP_ENV === 'production') {
      console.log = () => {};
    }
    auth.onAuthStateChanged(user => {
      if (user) {
        console.log('user:', user);
        if (socialService.isFacebookLogin()) getReview();

        listenToken();
        // find path and redirect the user
        let { pathname } = window.location;
        if (pathname !== '/test') {
          history.replace(
            pathname === '/' || pathname === '/login' ? '/world' : pathname
          );
          console.log('window.setInterval');
          window.setInterval(setRefreshToken, timer);
        }
      } else {
        if (listener) {
          listener();
          listener = null;
        }
        if (!open) {
          window.clearInterval(setRefreshToken);
        }
        setOpen(false);
        let { pathname } = window.location;
        if (pathname !== '/test') {
          // redirect the user to login screen
          history.replace('/login');
        }
      }
      setAuthChanged(true);
    });
    window.addEventListener('message', handleMessage);
    document.addEventListener('visibilitychange', handleVisibleChange);
    return () => {
      window.removeEventListener('message', handleMessage);
      document.removeEventListener('visibilitychange', handleVisibleChange);
    };
  }, []);

  const handleMessage = e => {
    switch (e.data.type) {
      case 'share':
        window.FB.ui(
          {
            method: 'share',
            href: `https://www.pttepworld.com/share?scene=${
              e.data.payload.scene
            }&score=${e.data.payload.score}`
          },
          response => {}
        );
        break;
      default:
        break;
    }
  };
  const handleVisibleChange = e => {
    // console.log('handleVisibleChange:', document.hidden);
    if (!document.hidden && auth.currentUser && token !== '') {
      dispatch({
        type: 'UPDATE_TOKEN',
        payload: token
      });
    }
  };

  const timer = 50 * 60 * 1000; //50minutes;
  const setRefreshToken = () => {
    // console.log('setRefreshToken:', new Date().toString());
    refreshToken();
  };
  const refreshToken = async () => {
    const currentToken = await auth.currentUser.getIdToken(true);
    token = currentToken;
    dispatch({
      type: 'UPDATE_TOKEN',
      payload: currentToken
    });
    socialService.updateToken(currentToken);
  };

  const listenToken = async () => {
    const currentToken = await auth.currentUser.getIdToken();
    await socialService.submitUser(currentToken, auth.currentUser);
    await socialService.updateToken(currentToken);
    console.log('currentToken: ', currentToken);
    token = currentToken;
    listener = database
      .collection(socialService.accessTokensCollection)
      .doc(auth.currentUser.uid)
      .onSnapshot(doc => {
        checkTokenFromListener(doc);
      });
  };

  const checkTokenFromListener = async doc => {
    if (!doc.metadata.fromCache) {
      //local
      if (doc.metadata.hasPendingWrites) {
      } else {
        //server
        const data = doc.data();
        const token = await auth.currentUser.getIdToken();
        if (data.accessToken !== token) {
          // console.log(`difference token time:${new Date().toString()}`);
          // console.log('currentToken in app:', token);
          // console.log(
          //   `difference data time:${new Date().toString()}, server current data: `,
          //   doc.data()
          // );
          window.clearInterval(setRefreshToken);
          setOpen(true);
        } else {
          console.log(
            `same token time:${new Date().toString()}, server current data: `,
            doc.data()
          );
        }
      }
    }
  };

  const getReview = async () => {
    try {
      const review = await socialService.getReview();
      if (review) {
        generalStore.dispatch({
          type: 'UPDATE_REVIEW',
          payload: review
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // TODO: remove this to let the players get through
  // if (process.env.REACT_APP_ENV === 'production') return <ComingSoon />;

  return !authChanged ? (
    <div>Loading ... </div>
  ) : (
    <>
      <Switch>
        <Route path="/login" component={Login} exact />
        <Route path="/world" component={World} exact />
        <Route path="/energy" component={Energy} exact />
        <Route path="/trees" component={Trees} exact />
        <Route path="/seas" component={Seas} exact />
        <Route path="/tour" component={Tour} exact />
        <Route path="/personality" component={Personality} exact />
        <Route path="/test" component={Test} exact />
      </Switch>
      <Loader />
      <LogoutPopup open={open} setOpen={setOpen} />
    </>
  );
};

export default withRouter(App);
