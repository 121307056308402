import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import TextViewer from './TextViewer';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: '#EFFBFF',
    border: 'solid 2px #C2FDFD'
  }
}));

const PersonalityInfoDialog = ({ content, open, onBackdropClick }) => {
  const { paper } = useStyles();
  return (
    <Dialog open={open} onBackdropClick={onBackdropClick} classes={{ paper }}>
      <DialogTitle>{content.title}</DialogTitle>
      <DialogContent>
        <TextViewer content={content.content} />
      </DialogContent>
    </Dialog>
  );
};

export default PersonalityInfoDialog;
