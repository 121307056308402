import React, { useState, useEffect, useRef, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import Scene from '../components/Scene';
import LoaderContext from '../context/loader';
import TourInfoDialog from '../components/TourInfoDialog';
import landmarksTH from '../data/landmarks-th.json';
import landmarksEN from '../data/landmarks-en.json';
import { TokenStore } from '../context/token';
import { enter } from '../services/analytics';
import socialService from '../services/social';

import { auth } from '../firebase';

const Tour = ({ history }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  const loaderContext = useContext(LoaderContext);
  const frame = useRef(null);
  const [ready, setReady] = useState(false);

  const fetchLandmark = (index, lang) => {
    frame.current.contentWindow.postMessage({ type: 'mute' }, '*');
    setShowDialog(true);
    const landmarks = lang === 'th' ? landmarksTH : landmarksEN;
    setTitle(landmarks[index].title);
    setContent(landmarks[index].details);
    setPhotos(landmarks[index].photos);
    setVideos(landmarks[index].videos);
  };

  const closeDialog = () => {
    frame.current.contentWindow.postMessage({ type: 'unmute' }, '*');
    setShowDialog(false);
    setTitle('');
    setContent([]);
    setPhotos([]);
    setVideos([]);
  };

  const handleMessage = e => {
    switch (e.data.type) {
      case 'loadScene':
        loaderContext.setStatus({ loading: true, scene: 'world' });
        setTimeout(() => history.push(`/${e.data.payload}`), 1000);
        break;
      case 'ready':
        setReady(true);
        getInitialize();
        break;
      case 'loaded':
        loaderContext.setStatus({ loading: false, scene: 'tour' });
        break;
      case 'dialog':
        fetchLandmark(e.data.payload.landmark, e.data.payload.lang);
        break;
      default:
        break;
    }
  };

  const getInitialize = async () => {
    const token = await auth.currentUser.getIdToken();
    frame.current.contentWindow.postMessage(
      {
        type: 'initialize',
        payload: {
          uId: auth.currentUser.uid,
          token,
          displayName: socialService.getDisplayName(),
          profilePicture: socialService.getPhotoUrl(),
          guest: auth.currentUser.email ? false : true
        }
      },
      '*'
    );
  };

  useEffect(() => {
    enter('tour');
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  //refreshToken
  const { state, dispatch } = React.useContext(TokenStore);
  useEffect(() => {
    if (state.refreshToken) {
      if (frame && ready) {
        frame.current.contentWindow.postMessage(
          {
            type: 'updateToken',
            payload: {
              token: state.token
            }
          },
          '*'
        );
      }
      console.log('refreshToken');
      dispatch({
        type: 'RESET'
      });
    }
  }, [state.refreshToken]);

  return (
    <>
      <Scene name="tour" frame={frame} />
      <TourInfoDialog
        content={content}
        onBackdropClick={closeDialog}
        open={showDialog}
        photos={photos}
        title={title}
        videos={videos}
      />
    </>
  );
};

export default withRouter(Tour);
