import React from 'react';

export const GeneralStore = React.createContext();

const initialState = {
  review: {
    star: 0,
    comment: ''
  }
};

function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE_REVIEW':
      return { ...state, review: { ...action.payload } };
    default:
      return state;
  }
}

export function GeneralProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <GeneralStore.Provider value={value}>
      {props.children}
    </GeneralStore.Provider>
  );
}
