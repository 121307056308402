import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@material-ui/core';
import SocialService from '../services/social';

const LogoutPopup = props => {
  const handleEntering = () => {
    window.setTimeout(() => {
      SocialService.logout();
    }, 3000);
  };
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      onEntering={handleEntering}
      open={props.open}
    >
      <DialogTitle>เกิดข้อผิดพลาด</DialogTitle>
      <DialogContent>
        <DialogContentText>
          มีคนล็อคอินไอดีของคุณ กำลังออกจากระบบ
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default LogoutPopup;
