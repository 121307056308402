import { auth, apiUrl, database, fieldValue } from '../firebase';
import axios from 'axios';

const accessTokensCollection = 'accessTokens';

const defaultPhotoUrl =
  'https://firebasestorage.googleapis.com/v0/b/progaming-pttepworld.appspot.com/o/default-profile-img-riggy-2.png?alt=media&token=e809b399-69ba-456e-9642-fa990d24598b';
//'https://firebasestorage.googleapis.com/v0/b/progaming-pttepworld.appspot.com/o/default-profile-img.png?alt=media&token=c95b4e7d-612b-4b64-882d-e7749f5e9a17';

const adminEmail = 'admin@pttepworld.com';

const getHeaders = token => {
  const headers = {};
  headers['Authorization'] = `Bearer ${token}`;
  return headers;
};
const getDisplayName = () =>
  auth.currentUser.displayName ||
  (auth.currentUser.email === adminEmail ? 'Admin' : 'Guest');
const getPhotoUrl = () => auth.currentUser.photoURL || defaultPhotoUrl;

const isFacebookLogin = () =>
  auth.currentUser &&
  !auth.currentUser.isAnonymous &&
  auth.currentUser.email !== adminEmail;

const submitUser = async (token, user) => {
  const userInfo = {
    displayName: user.displayName || 'Guest',
    email: user.email,
    facebookId: user.providerData[0] ? user.providerData[0].uid : '',
    photoUrl: user.photoURL,
    createdAt: new Date(user.metadata.creationTime).getTime(),
    updatedAt: new Date(user.metadata.lastSignInTime).getTime()
  };

  await axios.post(
    `${apiUrl}/users/`,
    {
      ...userInfo
    },
    {
      headers: getHeaders(token)
    }
  );
};
const updateToken = async accessToken => {
  await axios.post(
    `${apiUrl}/users/token`,
    {},
    {
      headers: getHeaders(accessToken)
    }
  );
};

const changeName = async (token, displayName) => {
  try {
    await axios.post(
      `${apiUrl}/users/changename`,
      {
        displayName
      },
      {
        headers: getHeaders(token)
      }
    );
    await auth.currentUser.reload();
  } catch (error) {
    console.log('error: ', error);
    throw error;
  }
};

const logout = () => {
  auth.signOut();
};

const getReview = async () => {
  try {
    const res = await database
      .collection('reviews')
      .doc(auth.currentUser.uid)
      .get();
    return res.exists ? res.data() : null;
  } catch (error) {
    throw error;
  }
};
const submitReview = async (star, comment) => {
  await database
    .collection('reviews')
    .doc(auth.currentUser.uid)
    .set({
      star,
      comment,
      createdAt: fieldValue.serverTimestamp()
    });
};

export default {
  getDisplayName,
  getPhotoUrl,
  submitUser,
  logout,
  updateToken,
  accessTokensCollection,
  isFacebookLogin,
  submitReview,
  getReview,
  changeName
};
