import React, { useEffect, useRef, useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';

import Scene from '../components/Scene';
import PersonalityInfoDialog from '../components/PersonalityInfoDialog';
import LoaderContext from '../context/loader';
import { TokenStore } from '../context/token';
import socialService from '../services/social';

import resultsTH from '../data/personality-test-result-th.json';
import resultsEN from '../data/personality-test-result-en.json';

import { auth } from '../firebase';

const Personality = ({ history }) => {
  const loaderContext = useContext(LoaderContext);
  const frame = useRef(null);
  const [ready, setReady] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [result, setResult] = useState({});

  const handleMessage = e => {
    switch (e.data.type) {
      case 'loadScene':
        loaderContext.setStatus({ loading: true, scene: 'world' });
        setTimeout(() => history.push(`/${e.data.payload}`), 1000);
        break;
      case 'ready':
        setReady(true);
        getInitialize();
        break;
      case 'loaded':
        loaderContext.setStatus({ loading: false, scene: 'personality' });
        break;
      case 'dialog':
        const results =
          e.data.payload.language === 'en' ? resultsEN : resultsTH;
        setResult(
          results[`test${e.data.payload.test}`][e.data.payload.personality]
        );
        setShowDialog(true);
        break;
      case 'share_personality':
        window.FB.ui(
          {
            method: 'share',
            href: `https://www.pttepworld.com/share?personality=${
              e.data.payload.personality
            }&test=${e.data.payload.test}&language=${e.data.payload.language}`
          },
          response => {}
        );
        break;
      default:
        break;
    }
  };
  const getInitialize = async () => {
    const token = await auth.currentUser.getIdToken();
    frame.current.contentWindow.postMessage(
      {
        type: 'initialize',
        payload: {
          uId: auth.currentUser.uid,
          token,
          displayName: socialService.getDisplayName(),
          profilePicture: socialService.getPhotoUrl(),
          guest: auth.currentUser.email ? false : true
        }
      },
      '*'
    );
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  //refreshToken
  const { state, dispatch } = React.useContext(TokenStore);
  useEffect(() => {
    if (state.refreshToken) {
      if (frame && ready) {
        frame.current.contentWindow.postMessage(
          {
            type: 'updateToken',
            payload: {
              token: state.token
            }
          },
          '*'
        );
      }
      console.log('refreshToken');
      dispatch({
        type: 'RESET'
      });
    }
  }, [state.refreshToken]);

  const closeDialog = () => {
    frame.current.contentWindow.postMessage({ type: 'unmute' }, '*');
    setShowDialog(false);
  };

  return (
    <>
      <Scene name="personality" frame={frame} />
      <PersonalityInfoDialog
        open={showDialog}
        onBackdropClick={closeDialog}
        content={result}
      />
    </>
  );
};

export default withRouter(Personality);
