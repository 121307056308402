import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import firebase from 'firebase/app';
import ReactMarkdown from 'react-markdown';
import { auth } from '../firebase';
import LoaderContext from '../context/loader';
import Scene from '../components/Scene';

const useStyles = makeStyles(
  createStyles(theme => ({
    banner: {
      maxWidth: '100%'
    },
    buttonPanel: {
      display: 'flex',
      flexDirection: 'column',
      left: '50%',
      position: 'absolute',
      top: '75%',
      transform: ({ scale }) => `scale(${scale})`,
      transformOrigin: 'top left'
    },
    linkText: {
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    loginButton: {
      left: '-50%',
      marginBottom: '3%',
      position: 'relative',
      top: '-50%'
    },
    remark: {
      position: 'absolute',
      right: '0px',
      top: '0px',
      padding: '4px 8px',
      backgroundColor: '#000000CC'
    },
    remarkText: {
      color: 'white',
      fontSize: '0.75em'
    }
  }))
);

const Login = () => {
  const [hoverFacebook, setHoverFacebook] = useState(false);
  const [hoverGuest, setHoverGuest] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [scale, setScale] = useState(1);
  const frame = useRef(null);
  const delayedResize = () => setTimeout(() => resize(), 1000);

  const loaderContext = useContext(LoaderContext);
  const { buttonPanel, loginButton, remark, remarkText, linkText } = useStyles({
    scale
  });

  const handleLogin = async () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    try {
      // const result = await auth.signInWithPopup(provider);
      // return result;
      auth.signInWithRedirect(provider);
      return null;
    } catch (error) {
      return error;
    }
  };

  const handleGuestLogin = async () => {
    try {
      const result = await auth.signInAnonymously();
      return result;
    } catch (error) {
      return error;
    }
  };

  const resize = () => {
    const currentScale = window.innerWidth / 1280;
    setScale(currentScale);
  };

  useEffect(() => {
    loaderContext.setStatus({ loading: false });
    window.addEventListener('resize', delayedResize);
    resize();
    return () => {
      window.removeEventListener('resize', delayedResize);
      loaderContext.setStatus({ loading: true, scene: 'world' });
    };
  }, []);

  return (
    <>
      <Scene name="login" frame={frame}>
        <div className={buttonPanel}>
          <img
            className={loginButton}
            src={`/images/login-button${hoverFacebook ? '-active' : ''}.png`}
            alt="facebook"
            onClick={handleLogin}
            onMouseOver={() => setHoverFacebook(true)}
            onMouseOut={() => setHoverFacebook(false)}
          />
          <img
            className={loginButton}
            src={`/images/guest-button${hoverGuest ? '-active' : ''}.png`}
            alt="guest"
            onClick={handleGuestLogin}
            onMouseOver={() => setHoverGuest(true)}
            onMouseOut={() => setHoverGuest(false)}
          />
        </div>
        {/* <div className={remark}>
          <Typography className={remarkText}>
            การคลิกปุ่มเพื่อเข้าสู่ระบบ แสดงถึงเจตจำนงว่าท่านได้ยอมรับ{' '}
            <span className={linkText} onClick={() => setShowDialog(true)}>
              เงื่อนไขการใช้งาน
            </span>{' '}
            แล้ว
          </Typography>
        </div> */}
        <TermsDialog showDialog={showDialog} setShowDialog={setShowDialog} />
      </Scene>
    </>
  );
};

const TermsDialog = ({ showDialog, setShowDialog }) => {
  const [terms, setTerms] = useState('');
  const { banner } = useStyles();

  useEffect(() => {
    fetch(require(`../data/terms.md`))
      .then(result => result.text())
      .then(text => setTerms(text));
  }, []);

  return (
    <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
      <DialogTitle>เงื่อนไขการลุ้นรับรางวัล PTTEP World</DialogTitle>
      <DialogContent>
        <img
          className={banner}
          src="/images/login-reward-banner.jpg"
          alt="reward"
        />
        <DialogContentText>
          <ReactMarkdown source={terms} />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default Login;
