import React from 'react';
import { Dialog, DialogTitle, DialogContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Carousel } from 'react-responsive-carousel';
import Expandable from './Expandable';
import { RemoveScroll } from 'react-remove-scroll';

const useStyles = makeStyles(theme => ({
  carousel: {
    marginBottom: '32px'
  },
  decorator1: {
    position: 'absolute',
    bottom: '0px',
    right: '15px',
    opacity: '0.2',
    pointerEvents: 'none'
  },
  decorator2: {
    position: 'absolute',
    top: '-40px',
    left: '0px'
  },
  decorator3: {
    position: 'absolute',
    top: '-5px',
    right: '0px'
  },
  paper: {
    backgroundColor: '#EFFBFF',
    border: 'solid 2px #C2FDFD'
  },
  responsiveImage: theme.responsiveImage
}));

const TourInfoDialog = ({
  content,
  onBackdropClick,
  open,
  photos,
  title,
  videos
}) => {
  const {
    carousel,
    decorator1,
    decorator2,
    decorator3,
    paper,
    responsiveImage
  } = useStyles();
  return (
    <RemoveScroll>
      <Dialog
        open={open}
        onBackdropClick={onBackdropClick}
        classes={{ paper }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <div className={carousel}>
            <Carousel
              showThumbs={false}
              showStatus={false}
              showIndicators={false}
            >
              {videos.map((video, index) => (
                <video
                  key={index}
                  className={responsiveImage}
                  src={video}
                  autoPlay
                  controls
                />
              ))}
              {photos.map((photo, index) => (
                <div key={index}>
                  <img src={photo} />
                </div>
              ))}
            </Carousel>
          </div>
          {content.map((block, index) => (
            <Expandable
              key={index}
              summary={block.summary}
              description={block.description}
            />
          ))}
        </DialogContent>
        <img className={decorator1} src="images/popup-01.png" alt="" />
      </Dialog>
    </RemoveScroll>
  );
};

export default TourInfoDialog;
