import React, {
  useEffect,
  useRef,
  useContext,
  useState,
  useCallback
} from 'react';
import { withRouter } from 'react-router-dom';
import { auth } from '../firebase';
import Scene from '../components/Scene';
import LoaderContext from '../context/loader';
import { TokenStore } from '../context/token';
import { enter } from '../services/analytics';

import ChangeNameDialog from '../components/ChangeNameDialog';

import socialService from '../services/social';

const World = ({ history }) => {
  const loaderContext = useContext(LoaderContext);
  const frame = useRef(null);
  //refreshToken
  const { state, dispatch } = React.useContext(TokenStore);
  const [ready, setReady] = useState(false);
  const [openChangeName, setOpenChangeName] = useState(false);
  const [currentName, setCurrentName] = useState(
    auth.currentUser.displayName || 'Guest'
  );
  const [changeNameLoading, setChangNameLoading] = useState(false);

  const handleMessage = useCallback(
    e => {
      console.log('handleMessage:', e.data);
      switch (e.data.type) {
        case 'loadScene':
          loaderContext.setStatus({ loading: true, scene: e.data.payload });
          setTimeout(() => history.push(`/${e.data.payload}`), 1000);
          break;
        case 'ready':
          setReady(true);
          getInitialize();
          break;
        case 'loaded':
          loaderContext.setStatus({ loading: false, scene: 'world' });
          break;
        case 'logout':
          auth.signOut();
          break;
        case 'changename':
          setOpenChangeName(true);
          break;
        default:
          break;
      }
    },
    [loaderContext, history]
  );

  const getInitialize = async () => {
    const token = await auth.currentUser.getIdToken();
    frame.current.contentWindow.postMessage(
      {
        type: 'initialize',
        payload: {
          uId: auth.currentUser.uid,
          token,
          displayName: socialService.getDisplayName(),
          profilePicture: socialService.getPhotoUrl(),
          guest: auth.currentUser.email ? false : true
        }
      },
      '*'
    );
  };

  const handleChangeName = async () => {
    setChangNameLoading(true);
    const token = await auth.currentUser.getIdToken();
    await socialService.changeName(token, currentName);
    console.log('currentName ', currentName);
    frame.current.contentWindow.postMessage(
      {
        type: 'newname',
        payload: {
          displayName: auth.currentUser.displayName
        }
      },
      '*'
    );
    setOpenChangeName(false);
    setChangNameLoading(false);
  };

  useEffect(() => {
    enter('world');
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [handleMessage]);

  useEffect(() => {
    if (state.refreshToken) {
      if (frame && ready) {
        frame.current.contentWindow.postMessage(
          {
            type: 'updateToken',
            payload: {
              token: state.token
            }
          },
          '*'
        );
      }
      console.log('refreshToken');
      dispatch({
        type: 'RESET'
      });
    }
  }, [dispatch, ready, state.refreshToken, state.token]);

  return (
    <>
      <Scene name="world" frame={frame} />
      <ChangeNameDialog
        open={openChangeName}
        onBackdropClick={() => setOpenChangeName(false)}
        nameState={currentName}
        onChangeName={setCurrentName}
        onSubmitName={handleChangeName}
        loading={changeNameLoading}
      />
    </>
  );
};

export default withRouter(World);
