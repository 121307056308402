import React, {useEffect, useContext, useRef} from 'react';
import Scene from '../components/Scene';
import LoaderContext from '../context/loader';

const Test = () => {
  const loaderContext = useContext(LoaderContext);
  const frame = useRef(null);

  useEffect(() => {
    loaderContext.setStatus({ loading: false, scene: 'trees' });
  }, []);

  return <Scene name="test" frame={frame} />;
};

export default Test;
