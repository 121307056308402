import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const config = {
  apiKey: 'AIzaSyBPEq2jt1lFCba2OtMjXp5iVIyBr2d7LJc',
  authDomain: 'progaming-pttepworld.firebaseapp.com',
  databaseURL: 'https://progaming-pttepworld.firebaseio.com',
  projectId: 'progaming-pttepworld',
  storageBucket: 'progaming-pttepworld.appspot.com',
  messagingSenderId: '288816809339'
};
firebase.initializeApp(config);

const auth = firebase.auth();
const database = firebase.firestore();
const storage = firebase.storage();

//server
const apiUrl =
  'https://asia-northeast1-progaming-pttepworld.cloudfunctions.net';
//local
// const apiUrl = 'http://localhost:5000/progaming-pttepworld/asia-northeast1';

const fieldValue = firebase.firestore.FieldValue;

export { auth, database, storage, apiUrl, fieldValue };
