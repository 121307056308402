import React, { useState, useEffect } from 'react';
import useScaler from '../hooks/Scaler';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { CropFree, Help } from '@material-ui/icons';
import HelpDialog from './HelpDialog';

const blockerWidth = 32;
const blockerOffset = -blockerWidth / 2;

const useStyles = makeStyles(theme => ({
  embedResponsive: theme.embedResponsive,
  root: {
    display: 'flex',
    height: ({ viewHeight }) => `${viewHeight}px`,
    margin: ({ vMargin, hMargin }) => `${vMargin}px ${hMargin}px`
  },
  scene: {
    position: 'absolute',
    width: props => props.width,
    height: props => props.height,
    transform: props => props.transform,
    transformOrigin: props => props.transformOrigin
  },
  leftBlocker: {
    position: 'absolute',
    top: '0px',
    left: `${blockerOffset}px`,
    width: `${blockerWidth}px`,
    height: '100%'
  },
  rightBlocker: {
    position: 'absolute',
    top: '0px',
    right: `${blockerOffset}px`,
    width: `${blockerWidth}px`,
    height: '100%'
  },
  topBlocker: {
    position: 'absolute',
    top: `${blockerOffset}px`,
    width: '100%',
    height: `${blockerWidth}px`
  },
  bottomBlocker: {
    position: 'absolute',
    bottom: `${blockerOffset}px`,
    width: '100%',
    height: `${blockerWidth}px`
  },
  iconPanel: {
    display: 'flex',
    position: 'absolute',
    bottom: '0px',
    left: ({ swapZoom }) => (swapZoom ? '0px' : null),
    right: ({ swapZoom }) => (swapZoom ? null : '0px')
  },
  zoomIcon: {
    color: 'white',
    margin: '8px'
  },
  feedbackPanel: {
    display: 'flex',
    cursor: 'pointer',
    padding: '8px',
    borderRadius: '8px 0px 0px 0px',
    color: 'white',
    backgroundColor: theme.palette.primary.main
  },
  feedbackIcon: {
    color: 'white',
    marginRight: '8px'
  }
}));

const Scene = ({ name, frame, swapZoom, children }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const {
    width,
    height,
    viewHeight,
    vMargin,
    hMargin,
    transform,
    transformOrigin,
    resize
  } = useScaler();
  const {
    embedResponsive,
    root,
    scene,
    leftBlocker,
    rightBlocker,
    topBlocker,
    bottomBlocker,
    iconPanel,
    zoomIcon,
    feedbackPanel,
    feedbackIcon
  } = useStyles({
    width,
    height,
    viewHeight,
    vMargin,
    hMargin,
    transform,
    transformOrigin,
    swapZoom
  });

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const handleMessage = e => {
    switch (e.data.type) {
      case 'endgame':
        // setShowDialog(true);
        break;
      case 'loaded':
        resize();
        setLoaded(true);
        break;
    }
  };

  const bucketUrl =
    process.env.REACT_APP_ENV === 'production'
      ? 'https://www.pttepworld.space'
      : process.env.REACT_APP_ENV === 'development'
      ? 'https://dev.pttepworld.space'
      : '';

  return (
    <div className={root}>
      <div className={embedResponsive}>
        <iframe
          style={{ visibility: loaded ? 'visible' : 'hidden' }}
          src={`/scenes/index.html?bucket_url=${bucketUrl}&name=${name}&verbose=${
            process.env.REACT_APP_ENV === 'production' ? 'false' : 'true'
          }`}
          title="content"
          ref={frame}
          scrolling="no"
          className={scene}
          frameBorder="0"
          allowFullScreen
          onLoad={() => {
            console.log('finished loading');
          }}
        />
        {// only iOS needs these blockers
        navigator.userAgent.match(/(iPad|iPhone|iPod)/g) && (
          <>
            <div className={leftBlocker} onClick={e => e.stopPropagation()} />
            <div className={rightBlocker} onClick={e => e.stopPropagation()} />
            <div className={topBlocker} onClick={e => e.stopPropagation()} />
            <div className={bottomBlocker} onClick={e => e.stopPropagation()} />
          </>
        )}
        {children}
        <div className={iconPanel}>
          {navigator.userAgent.match(/(Android)/g) &&
            name !== 'tour' &&
            name !== 'personality' && (
              <CropFree
                className={zoomIcon}
                onClick={() => frame.current.requestFullscreen()}
              />
            )}
          {name !== 'energy' &&
            name !== 'seas' &&
            name !== 'trees' &&
            name !== 'tour' &&
            name !== 'personality' && (
              <div
                className={feedbackPanel}
                onClick={() => setShowDialog(true)}
              >
                <Help className={feedbackIcon} />
                <Typography color="inherit">Help</Typography>
              </div>
            )}
        </div>
        <HelpDialog showDialog={showDialog} setShowDialog={setShowDialog} />
      </div>
    </div>
  );
};

export default Scene;
