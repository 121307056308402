import React, { useState, useEffect, useContext } from 'react';
import { CircularProgress, Fade, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { database } from '../firebase';
import useScaler from '../hooks/Scaler';
import LoaderContext from '../context/loader';

const defaultTip = { message: 'ยินดีต้อนรับสู่ PTTEP World' };
let tips = [];

const useStyles = makeStyles(theme => {
  return {
    embedResponsive: theme.embedResponsive,
    root: {
      backgroundColor: 'black',
      display: 'flex',
      position: 'absolute',
      top: '0px',
      width: ({ viewWidth }) => `${viewWidth}px`,
      height: ({ viewHeight }) => `${viewHeight}px`,
      margin: ({ vMargin, hMargin }) => `${vMargin}px ${hMargin}px`
    },
    loader: {
      display: 'flex',
      position: 'absolute',
      top: `0px`,
      color: 'white',
      backgroundColor: 'black',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: props => `${props.viewWidth}px`,
      height: props => `${props.viewHeight}px`
    },
    progress: {
      marginTop: '16px'
    },
    loaderImage: {
      width: '100%',
      height: '100%'
    },
    loaderIcon: {
      bottom: '4%',
      position: 'absolute',
      right: '4%',
      width: '5%'
    },
    loaderText: {
      bottom: '4%',
      color: 'white',
      position: 'absolute',
      left: '4%',
      transform: ({ transform }) => transform,
      transformOrigin: `bottom left`
    }
  };
});

const Loader = ({ children }) => {
  const [tip, setTip] = useState(defaultTip);
  const [delayed, setDelayed] = useState(false);
  const loaderContext = useContext(LoaderContext);
  const { viewWidth, viewHeight, vMargin, hMargin, transform } = useScaler();
  const {
    embedResponsive,
    root,
    loader,
    loaderImage,
    loaderText,
    progress
  } = useStyles({
    transform,
    viewWidth,
    viewHeight,
    vMargin,
    hMargin
  });

  const randomTip = () => {
    const filtered = tips.filter(
      tip => tip.scene === loaderContext.status.scene
    );
    const index = Math.floor(Math.random() * filtered.length);
    setTip(filtered[index] || defaultTip);
  };

  useEffect(() => {
    fetchTips();
  }, []);

  useEffect(() => {
    setTimeout(() => setDelayed(true), 5000);
    setDelayed(false);
    randomTip();
  }, [loaderContext.status.scene]);

  const fetchTips = async () => {
    const docs = await database.collection('tips').get();
    docs.forEach(doc => (tips = [...tips, doc.data()]));
  };
  return (
    <Fade in={!delayed || loaderContext.status.loading} unmountOnExit={true}>
      <div className={root}>
        <div className={embedResponsive}>
          <div className={loader}>
            <img
              className={loaderImage}
              src={`images/loading-${loaderContext.status.scene ||
                'world'}.jpg`}
              alt=""
            />
            <LoaderIcon />
            <Typography className={loaderText} variant="h2">
              Loading ...
            </Typography>
            <Typography
              variant="h4"
              color="inherit"
              style={{ display: 'none' }}
            >
              {tip.message}
            </Typography>
            <CircularProgress
              className={progress}
              style={{ display: 'none' }}
            />
          </div>
        </div>
      </div>
    </Fade>
  );
};

const LoaderIcon = () => {
  const { loaderIcon } = useStyles();
  const [frame, setFrame] = useState(0);
  const delay = 300;
  useEffect(() => {
    const handler = setInterval(updateFrame, delay);
    return () => clearInterval(handler);
  }, []);
  const updateFrame = () => {
    const frame = Math.round(new Date() / delay) % 3;
    setFrame(frame);
  };
  return (
    <img className={loaderIcon} src={`images/loader-${frame}.png`} alt="" />
  );
};

export default Loader;
